html {
  scroll-behavior: smooth;
}
.gsc-control-searchbox-only {
  background-color: inherit !important;
  border: none !important;
}

.gsc-control-searchbox-only .gsc-input-box {
  height: 45px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding-left: 30px;
  padding-top: 5px;
}
.gsc-control-searchbox-only .gsc-search-box {
  max-width: 500px;
  margin: auto;
}
.gsc-control-searchbox-only .gsc-search-button {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
	margin-left: -1px;
	height: 46px;
}
.gsc-control-searchbox-only .gsc-search-button svg {
	max-width: none !important;
}
.gsc-control-searchbox-only td.gsc-input {
	padding-right: 0px !important;
}
.headerDiv {
  background-color: #F5F6F8;  
}
/* for all modal box */
.ui.page.modals.visible.active {
  z-index: 999999;
}

/* Make footer sticy if page content is short  */
.topResearchBar {
  position: fixed !important;
  width: 100% !important;
  margin: auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  top: 0px !important;
  padding-top: 5px !important;
  z-index: 10005 !important;
  background-color: white !important;
}
.actionTranslationBar {
  display: flex;
  flex-flow: row;
  position: fixed !important;
  width: 100% !important;
  margin: auto !important;
  top: 0px !important;
  padding-top: 100px !important;
  padding-bottom: 25px !important;
  z-index: 10004 !important;
  background-color: white !important;
  align-items: center;
}
.actionResearchBar {
  position: fixed !important;
  width: 100% !important;
  margin: auto !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  top: 0px !important;
  padding-top: 100px !important;
  padding-bottom: 15px !important;
  z-index: 10004 !important;
  background-color: white !important;
}
.fixedpos {
  width: auto;
  position: fixed !important;
  margin: auto !important;
  z-index: 10003;
  background-color: white !important;
  top: 150px !important;
}
.translationHeader {
  width: auto;
  position: fixed !important;
  margin: auto !important;
  z-index: 10003;
  background-color: white !important;
  top: 175px !important;
}
.margintopmain {
  margin-top: 160px;
}
.Site, .Site-home {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .Site {
    overflow: scroll;
  }
}
@media (max-width: 1099px) {
  .Site-home {
    overflow-x: scroll;
  }
}
.Site-content {
  flex: 1;
  display: flex;
}
.page-content  {
  margin-top: 90px;
  margin-left: 160px;
  margin-right: 10%;
  padding-bottom: 5em;
  width: 100%;
}
@media (max-width: 1023px) {
  .Site-content {
    flex-direction: column;
  }
  .page-content {
    width: 95% !important;
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .page-content {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 768px) {
  .search-english-switch {
    display: none;
  }
}

/* Nav styling*/
.navmenu-logo-img {
  height: 35px;
}
.navmenu-logo-img-mobile {
  height: 30px;
  margin-left: 15px;
}
.navmenu.ui.inverted.text.menu,
.navmenu.ui.inverted.text.menu .item,
.navmenu.ui.inverted.text.menu .item:hover,
.navmenu.ui.inverted.text.menu .active.item {
  background-color: #506D84 !important;
}
.navmenu-mobile .ui.grid {
  margin-top: 3px;
  margin-bottom: 3px;
  border-bottom: 1px solid #e0e1e1;
}
.navmenu.ui.text.menu {
  margin: 0 0 15px 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* home hero*/
.hero-title {
  margin-top: 40px !important;
}

.hero-top-margin {
  margin-top: 40px !important;
}
.hero-bottom-margin {
  margin-bottom: 20px !important;
}

.home-hero-bg {
  background: linear-gradient(180deg, #EBF6FC 80%, transparent 0%),
              linear-gradient(172deg, #EBF6FC 67%, transparent 0%);
  background-repeat: no-repeat;
}

.home-hero-title {
  margin-top: 150px !important;
}
.home-hero-subtitle {
  margin-top: 20px;
}
.home-hero-cta {
  margin-top: 25px;
}

.home-hero-wrap {
  position: relative;
}
.home-hero-image-main {
  float: right;
}
.page-section-padding {
  padding-top: 40px !important;
}
.page-subsection-padding {
  padding-top: 20px !important;
}
.page-colored-section-padding {
  padding-top: 50px !important;
}
.page-colored-section-margin-bottom {
  padding-top: 30px !important;
}

.page-section-margin {
  margin-top: 100px !important;
}

.vertical-middle {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.page-header, .page-body {
  margin-top: 40px;
}

.site-footer-cta {
  margin-top: 2em !important;
}

.site-footer-logo {
  display: inline-block !important;
  margin-right: 30px;
  height: 50px;
}
.site-footer-copyright {
  text-align: center;
  font-size: 0.8em;
  padding-top: 7px;
  padding-bottom: 7px;
}
.research-button-footer {
  margin-top: -5px !important;
}
.get-premium-price, .lp-message {
  color: #1278B9;
}
.lp-hero-bg {
  background: #EBF6FC;
}
.lp-hero-img-container {
  display: flex !important;
}
#lp_grandma_animation, #lp_animated_gif, #lp_youtube_video {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.lp_hero_animation, .lp_hero_animation_container {
  height: 525px !important;
  width: 521px !important;
}
@media (max-width: 1070px) {
  .lp_hero_animation, .lp_hero_animation_container {
    height: 375px !important;
    width: 372.1px !important;
  }
}
.lp-animated-gif-img {
  width: 100%;
}
.lp-youtube-video {
  border: 3px solid white;
  border-radius: 7px;
}

.get-premium-you-can-trust-bg {
  background: #EBF6FC;
}
.get-premium-you-can-trust-img {
  width: 250px;
  height: auto;
  margin-top: -130px;
}
.get-premium-subtitle {
  font-size: 1.2em;
  font-weight: 600;
}

.price {
  position: relative;
  padding-top:  20px;
  padding-bottom: 20px;
}
.price-dollar {
  position: absolute;
  top: 16px;
  font-size: 20px;
}
.price-amount {
  padding-left: 10px;
  font-size: 38px;
}
.price-month {
  font-size: 20px;
}

.get-premium-background-hexagon {
  position: absolute;
  left: 34%;
  top: -20px;
}
@media (max-width: 767px) {
  .get-premium-background-hexagon {
    left: 42%;
    top: -10px;
  }
}

.get-premium-hexagon-top {
  width: 0;
  border-bottom: 30px solid #ebf6fc;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
}
.get-premium-hexagon-middle {
  width: 120px;
  height: 80px;
  background-color: #ebf6fc;
}
.get-premium-hexagon-bottom {
  width: 0;
  border-top: 30px solid #ebf6fc;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
}

.howto-vertical-img {
  margin-top: 40px;
  margin-bottom: 40px;
}

.image-background-circle {
  position: absolute;
  left: 60px;
  top: -10px;
  background: #ebf6fc;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

/* for Premium popup */
.footerBlurInfo {
  margin-top : 20px !important;
}
.footerBlurInfo > .row {
  padding-top : 0px !important;
  padding-bottom: 5px !important
}
.footerBlurInfo > .row > .column{
  padding-left: 30px !important; 
  text-align: left;
  font-size: 0.8rem;
}
.footerBlurInfo > .row > .column > label{
  font-weight: bold;
}
.footerLinkRow {
  font-size: 0.8rem;
  margin-left: 5px;
  padding-bottom: 0px !important;
  padding-top: 5px !important;
}
.footerLinkRow > span {
  padding-top: 5px !important;
  padding-left: 10px !important;
}
.footerLinkRow > span > a {
  cursor: pointer;
}
.footerAffiliate {
  padding-top: 0px !important;
  font-size: 0.8rem;
  align-items: center !important;
}
.footerAffiliate > .logoBox {
  padding: 10px !important;
  padding-left: 15px !important;
  text-align:left
}
.footerAffiliate > .logoBox > img {
  max-height: 50px !important;
}
.footerAffiliate > .affInfo {
  padding-left: 0px !important;
  padding-right: 10px !important;
  line-height: 1.5;
  text-align: left;
}
.footerLoading {
  width: 100%;
  min-height: 70px;
} 
.signupBody {
  background-color: #EFF1F8;
  width: 100%;
  min-height: 400px;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.signupBody > .form > .btm_button {
  bottom: 5px !important;
  position: absolute;
}
.codesent_email {
  font-size: 0.8rem;
  padding-bottom: 30px;
}
.popup_header {
  color: #1278B9;
  padding-bottom: 20px;
  line-height: 1.5;
}
.resend_otp {
  padding-top: 10px;
}
.enroll_descriptor > label {
  font-weight: 400 !important;
  color: #1278B9 !important;
  padding-bottom: 10px;
}
.headerAction {
  width: 100%;
  min-height: 40px;
  padding: 5px 0px 0px 0px !important;
}
.headerAction > .hIcon {
  text-align: left !important;
  padding-left: 0px !important;
  cursor: pointer !important;
}
.headerAction > .htext {
  text-align: right !important;
  padding-right: 10px;
  padding-top: 8px !important;
  font-size: 1.1rem;
  color: #1278B9 !important;
}
.enrollForm {
  width: 100%;
}
.enrollForm > .tabular > a {
  font-size: 0.8rem !important;
  color: #1278B9 !important;
}
.premium-error {
  max-height: 80px;
}
.premium-info {
  max-height: 100px;
}

/* common*/
.btn-icon {
  margin-top: -5px !important;
}
.dark-bluegray-bg {
  background: #6A8BA6;
}
.light-blue-bg {
  background: #EBF6FC;
}
.text-white {
  color: white !important;
}
.text-center {
  text-align: center;
}
.check-box-line {
  padding-bottom: 15px !important;
}
.content.check-box-content {
  padding-top: 4px !important;
}

.displayBlock {
  display: block !important;
}
.displayNone {
  display: none !important;
}
.ConfigTable {
  width: 100%;
  height: 100%;
}
.DetectTable {
    width: 100vw;
    min-height: 400px;
    height: 100%;
    overflow-x: auto;
    padding-left: 25px;
    padding-right: 25px;
}
.DetectTable button {
  font-weight: 500 !important;
  padding: 5px !important;
  font-size: 0.8rem !important;
}
.key-action {
  width:100px;
}
.key-action > button {
  font-size: 0.7em !important;
}
.section-input {
  width:200px;
}
.section-input-header > div > input {
  font-weight:bold !important;
  font-size:1.1em !important;
}
.section-dropdown {
  width:100px;
}
.section-action {
  width:120px;
}
.section-action > button {
  font-size: 0.8em !important;
}
.save-config {
  width:150px;
  font-size: 0.8em !important;
}
.config-checkbox {
  width:30px;
  height:30px;
}
.detectRuleSelect {
  width: 30px !important;
  padding: 0px !important;
  padding-left: 5px !important;
}
.detectRuleAction {
  width:30px !important;;
}
.detectRuleActive {
  width:30px !important;;
}
.detectRuleAppId {
  width:100px;
}
.detectRuleFrags {
  width:250px;
}
.detectRuleUri {
  width:250px;
}
.detectCheckBoxDiv {
  padding-top: 2rem !important;
}
.detectTopVerticalAlign {
  vertical-align: top !important;
}
.notFoundFragMatch > input {
  background-color: #fccccc !important;
}
.foundFragMatch > input{
  background-color: #d6e9f5 !important;
}
.foundExceptMatch > input{
  border-color: #328FC9 !important;
  border-width: 2px !important;
  border-style: solid !important;
}
.foundFragMatch > span{
  background-color: #d6e9f5 !important;
}
.foundExceptMatch > span{
  border-color: #328FC9 !important;
  border-width: 2px !important;
  border-style: solid !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.deletedWord {
  background-color: rgb(255, 220, 220);
  text-decoration: line-through;
}
.insertedWord {
  background-color: rgb(201, 238, 211);
  text-decoration: none;
}
.detectRuleTooltip {
  min-width: 50px !important;
  min-height: 70px !important;
}

.TranslationTable {
  min-height: 400px;
  width: auto;
  height: 100%;
  overflow-x: scroll;
  padding-left: 10%;
  padding-right: 10%;
}
.TranslationTable button {
  font-weight: 500 !important;
  font-size: 0.8rem !important;
}

.identifyTable {
    min-height: 400px;
    height: 100%;
    overflow-x: scroll;
    padding-left: 25px;
    padding-right: 25px;
}
.identifyTable button {
  font-weight: 500 !important;
  padding: 5px !important;
  font-size: 0.8rem !important;
}
.identifyRuleSelect {
  width: 76px !important;
  padding: 0px !important;
  padding-left: 5px !important;
}
.identifyRuleAction {
  min-width:30px !important;;
}
.identifyRuleActive {
  min-width:30px !important;;
}
.identifyRuleId {
  min-width:120px !important;
}
.identifyRuleOrder {
  min-width: 50px !important;
}
.identifyRuleOrder > input {
  width: 50px !important;
}
.identifyRulePage {
  min-width:250px;
}
.identifyRuleSource {
  min-width:250px;
}
.identifyRuleBlur {
  min-width:250px;
}
.identifyCheckBoxDiv {
  padding-top: 2rem !important;
}
.identifyTopVerticalAlign {
  vertical-align: top !important;
}
.identifyRuleTooltip {
  min-width: 50px !important;
  min-height: 70px !important;
}

.dlTable {
  min-height: 400px;
  height: 100%;
  overflow-x: scroll;
  padding-left: 25px;
  padding-right: 25px;
}
.dlTable button {
font-weight: 500 !important;
padding: 5px !important;
font-size: 0.8rem !important;
}
.dlRuleSelect {
  width: 30px !important;
  padding: 0px !important;
  padding-left: 5px !important;
}
.dlRuleAction {
  width:30px !important;;
}
.dlRuleActive {
  width:30px !important;;
}
.dlRuleId {
  width:90px !important;
}
.dlRuleURIs {
  width: 250px !important;
}
.dlRuleDLs {
  width: 250px !important;
}
.dlRuleApps {
  width: 250px !important;
}
.dlRuleTooltip {
  min-width: 50px !important;
  min-height: 70px !important;
}
.dlRuleBoxed {
  padding: 10px;
  border: 2px dashed #d6e9f5;
  border-radius: 10px;
  margin-bottom: 5px;
}
.dlRuleBoxed div label {
  font-size:0.8em !important;
}
.dlRuleBoxed .dlRuleBoxedDelete {
  float: right;
  margin-top: -10px;
  margin-right: -15px;
}
.dlRefreshIcon {
  padding: 10px;
  background-color: rgb(189, 197, 204);
  border-radius: 10px;
  z-index: 99999;
}
.dlRefreshIcon > i {
  font-size: 1.2rem;
  color: rgb(46, 167, 9);  
}
.mediamodal {
  display: none;
  position: fixed;
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}

.contentmodal {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 900px;
}

.closemodal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 30px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.welcome-hero-bg {
  margin-top: 16px;
}
@media screen and (min-width: 1200px) {
	.welcome-hero-bg {
    background: linear-gradient(310deg, #ffffff 30%, transparent 0%), 
                linear-gradient(230deg, #ffffff 30%, transparent 0%), 
    #EBF6FC;
  }
}
@media screen and (min-width: 600px) and (max-width: 1199px) {
	.welcome-hero-bg {
    background: linear-gradient(310deg, #ffffff 40%, transparent 0%), 
                linear-gradient(230deg, #ffffff 40%, transparent 0%), 
    #EBF6FC;
  }
}
@media screen and (max-width: 599px) {
	.welcome-hero-bg {
    background: #EBF6FC;
  }
}
.welcome-icon {
  height: 35px; 
  width: 35px;
  margin: 0px !important;
  margin-left: 20px;
  margin-right: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
.welcome-video {
  border: #DCE5ED solid 4px;
  border-radius: 4px;
  max-width: 700px;
}
.welcome-video-steps {
  display: flex;
  margin-top: 20px;
}
.welcome-video-step-number {
  font-size: 4em;
  margin-top: 40px;
  margin-left: 10px;
  color: #506D84;
  background: linear-gradient(180deg, #ffffff 25%, transparent 0%), #F5F6F8
}
.welcome-video-step-description {
  margin-left: 30px;
  margin-top: 20px;
}
.welcome-video-step-description-text {
  margin-top: 10px;
}
.welcome-sharelinks-section {
  box-shadow: none !important;
  background-color: #F5F6F8 !important;
}

.explanation {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #EBF6FC;
}

.explanation-container{
  position: fixed;
  top: 100px;
  right: -20px;
}

.explanation-boxes{
  margin: 10px;
  width: 50%;
}

.explanation-image{
  height: 60px;
  width: 60px;
}

.explanation-text{
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-top: 5px;
}

.explanation-arrow-line{
  height: 1px;
  width: 75%;
  background-color: gray;
  margin-top: 25px;
  float: left;
}

.explanation-arrow-circle{
  height: 20px;
  width: 20px;
  background-color: #555;
  border-radius: 50%;
  margin-left: 75%;
  margin-top: 15px;
}

.explanation-column-div{
  display: flex;
  align-items: center;
}

.contact-form-margin {
  margin-top: 20px;
}

.uninstalled-hero-table {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
}
.uninstalled-hero-table tr td {
  padding-bottom: 7px;
  padding-right: 10px;
}
.uninstalled-hero-img {
  margin-top: 35px;
}

.support-hero-img {
  margin-top: 30px;
  height: 215px;
  width: auto;
}

.uninstalled-header{
  line-height: 75px;
  margin-left: -40px;
}

.uninstalled-offset{
  margin-left: -40px;
}

.uninstalled-row{
  margin-top: -20px;
}

.uninstalled-top-row {
  margin-top: 30px;
}

.uninstalled-bg{
  position: absolute;
  left: 0;
  top: 92px;
  width: 100%;
  height: 210px;
  background-color: #EBF6FC;
  z-index: -3;
}

.uninstalled-checkbox-grid {
  margin-top: 70px;
}

.uninstalled-redirect-margin {
  margin-top: 60px;
}

.uninstalled-checkbox-margin {
  margin-top: -20px;
}

input[type=text], select {
  width: 100%;
  padding: 40px 150px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit], select {
  width: 100%;
  padding: 40px 150px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.support-header-text {
  line-height: 210px;
  width: 60%;
}

.support-image {
  position: absolute;
  top: 70px;
  right: 170px;
  height: 250px;
  width: 250px;
  z-index: -1;
}

.support-email {
  margin-top: 20px;
}

.support-margin{
  margin-top: 15px;
}



#pointer {
  width: 200px;
  height: 40px;
  position: relative;
  background: red;
  margin-top: 13px;
  /* margin-right: -15px; */
}
#pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;

 
}
#pointer:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid red;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  
}

.button{
  background-color: #1278B9;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 50px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  border-radius: 8px;
}

.apps-title-row {
  margin-top: 35px;
}

.apps-title-label {
  font-size: 20px;
  margin-top: 60px;
  color: #328FC9; 
}
.apps-hero-logo-img {
  margin-left: auto;
  margin-right: auto;
  height: 40px;
}
/*.apps-hero-deceptor-content {
  display: flex;
  align-content: center;
}*/
.apps-hero-deceptor-details {
  margin-left: -5px;
}
.apps-hero-deceptor-details h1 {
  word-break: break-all;
}
@media screen and (max-width: 768px) {
  .apps-hero-deceptor-cicle {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 769px) {
  .apps-hero-deceptor-cicle {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding: 15px 0px 20px 0px;
  }
}
.apps-hero-deceptor-cicle div {
  padding: 15px 35px;
  font-size: 1em;
  line-height: 140%;
  color: #6a8ba6;
}
.apps-description-table tr td {
  padding: 5px 10px;
  font-size: 0.9em;
}
.apps-description-table td:nth-child(1) {
  background-color: #506D84;
  text-align: right;
  color: #FFF;
}
.apps-description-table td:nth-child(2) {
  background-color: #C8D2DE;
}
.apps-help-links {
  line-height: 1.7;
}
.apps-deceptor-badge {
  text-align: center;
  color: #6a8ba6;
  background: white;
  border-radius: 20px;
  margin-top: 7px;
  border: solid 2px #6A8BA6;
  height: 140px;
}
.apps-certified-badge {
  text-align: center;
  color: #f6921e;
  background: white;
  border-radius: 20px;
  margin-top: 7px;
  border: solid 2px #f6921e;
  height: 120px;
}
.apps-certified-badge img {
  padding-top: 12px;
  padding-left: 5px;
  padding-right: 10px;
  width: 100px;
  transform: rotate(-30deg);
}
.apps-deceptor-badge img {
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.apps-deceptor-badge div {
  padding-bottom: 15px;
}
.apps-page-links-1 {
  float: right;
  color: #328FC9; 
  cursor: pointer;
}

.apps-page-links-2{
  margin-top: 20px;
  color: #328FC9;
  cursor: pointer;
}

.apps-margin {
  margin-top: 120px;
}

.apps-learn-more-link {
  margin-top: 20px;
}

.apps-section-title {
  margin-top: 60px;
  font-size: 25px;
  color: #328FC9; 
}

.apps-logo-title{
  margin-top: 90px;
  font-size: 25px;
  color: #328FC9;
}

.apps-blocked {
  margin-top: 40px;
}

.apps-av-column-set-1{
  column-count: 1; 
  column-gap: 20px;
  width: 100%; 
  margin-top: 20px;
}

.apps-av-column-set-2{
  column-count: 2; 
  column-gap: 20px;
  width: 100%; 
  margin-top: 20px;
}

.apps-av-column-set-3{
  column-count: 3; 
  column-gap: 20px;
  width: 100%; 
  margin-top: 20px;
}

.apps-av-column-set-4{
  column-count: 4; 
  column-gap: 20px;
  width: 100%; 
  margin-top: 20px;
}

.ul-apps {
  list-style: none;
}

.apps-violation {
  margin-top: 70px;
  font-size: 25px;
  color: #328FC9; 
}

.violation-acr {
  margin-top: 50px;
}

.violation-section {
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
}
.violation-section img {
  border: 1px solid #707070;
  cursor: pointer;
  max-width: 100%;
  height: auto;
}

.hero-bg-circle {
  position: absolute;
  top: 12px;
  height: 225px;
  width: 225px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
}

.get-premium-details-section {
  font-size: 0.8em;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.supp-card-img {
  height: 80px;
  width: auto;
  margin-bottom: 20px;
}
.supp-card-desc {
  font-size: 1.1em;
  line-height: 1.3;
  color: rgba(0,0,0,.87);
  font-family: Source Sans Pro,Helvetica Neue,Arial,Helvetica,sans-serif;
}
.supp-card-field > .supp-div-btn {
  margin-top: 20px;
  padding-left: 10px !important;
}
.supp-card-field > label {
  font-weight: lighter !important;
  font-size: 1.4em !important;
  line-height: 1.3 !important;
  color: rgba(0,0,0,.87) !important;
  padding-left: 10px !important;
  font-family: Source Sans Pro,Helvetica Neue,Arial,Helvetica,sans-serif !important;
}
.blur-demo-border {
  border-radius: 11px;
  border: 2.5px solid #4996CE;
  width: 500px;
  height: 320px;
  background-color: white;
  margin: 5px;
  font-size: 10px;
  padding-bottom: 10px;
}

.blur-demo-search-result {
  width: 100%;
  margin: 1%;
  margin-left: 5%;
  padding-top: 1px;
  padding-left: 2px;
  background-color: white;
  color:blue
}

.demo-search-section {
  width: 60%;
  float: left;
}

.demo-ad-section {
  float: left;
  width: 40%;
  margin-top: 5%;
}

.blur-demo-ad {
  width: 53%;
  margin-right: 8%;
  float: right;
}
.blur-demo-ad-text {
  width: 50%;
  line-height: 120%;
  margin-right: 8%;
  margin-bottom: 7%;
  float: right;
  font-size: 8px;
}
.demo-deceptor-blur {
  background-color: #FFF7E9;
  transition: 0.3s;
}
.demo-item .demo-deceptor-blur:hover {
  filter: blur(0px);
}
.blur-int-3 .demo-deceptor-blur {
  filter: blur(3px);
}
.blur-int-5 .demo-deceptor-blur {
  filter: blur(5px);
}
.blur-int-10 .demo-deceptor-blur {
  filter: blur(10px);
}
.blur-demo-subtext {
  color: green;
  font-size: 9px;
  margin: 0px
}
.blur-demo-grey {
  color: grey;
}
.blur-demo-deceptor-logo {
  width: 40px !important;
  height: 40px !important;
  transition: 1s;
  margin-left: 29.5%;
  margin-top: -6%;
  position: absolute;
  z-index: 420691337;
}
.blur-demo-deceptor:hover ~ .blur-demo-deceptor-logo {
  width: 40px !important;
  height: 40px !important;
  margin-left: 36%;
  margin-top: -9.5%;
  transition: 1s;
}
.blur-demo-popup {
  opacity: 1.0 !important;
  position: absolute; 
  width: 270px; 
  color: black !important;
  line-height: 12px;
  height: 180px; 
  background-color: white; 
  transition: 1s; 
  border-style: solid;
  border-radius: 3px; 
  border-color: #707070; 
  border-width: 1px;
  pointer-events: none;
}
.blur-demo-deceptor:hover{
  color: black !important;
  opacity: 1.0 !important;
  position: absolute; 
  width: 270px; 
  height: 180px; 
  background-color: white; 
  transition: 1s; 
  line-height: 12px;
  border-style: solid;
  border-radius: 3px; 
  border-color: #707070; 
  border-width: 1px;
   pointer-events: none;
}
.blur-demo-textbox {
  width: 80%; 
  height: 50%;   
  margin-left: 10%; 
  margin-right: 8%; 
  margin-top: 23%; 
  font-size: 10px; 
  border-top: 1px solid; 
  border-color: #707070; 
  display: block
}
.blur-demo-warningbox {
  width: 65%; 
  height: 20%;  
  color:black;
  font-size: 10px; 
  float: right; 
  margin-left: 20%; 
  margin-right: 5%; 
  margin-top: 10%; 
}
.blur-demo-advicebox {
  bottom:10px; 
  margin-left: 10%; 
  font-size: 10px;
}
.blur-demo-orange {
  color: #F59423; 
  font-size: 12px;
}
.blur-demo-popup-collision {
  width: 400px;
  height: 70px;
  z-index: 9999;
}
.blurcontrol {
  height: 50px;
  width: 100%;
}
.blurcontroltext {
  margin-right: -15px;
  font-weight: bold;
  line-height: 25px;
  margin-left: 20px; 
  margin-top: 12px;
  height: 25px;
  float: left;
  font-size: 12px;
  color: #707070;
}
.blurcontrolbuttoncontainer {
  margin-left: 20px;
  margin-top: 12px;
  width: 225px;
  height: 25px;
  float: left;
}
.activeblurcontrol {
  font-weight: bold !important;
  background-color: #707070 !important;
  color: white !important;
}
.blurcontrolbutton {
  border-style: solid;
  text-align: center;
  line-height: 25px;
  border-width: 1px;
  user-select: none;
  height: 100%;
  float: left;
  width: 23%;
  background-color: white;
  border-color: #707070;
  color: #707070;
  font-size: 12px;
}
.blurcontrolbuttonnone {
  border-radius: 6px 0px 0px 6px; 
}
.blurcontrolbuttonlow {
  border-radius: 0px;
  border-left: 0px;
}
.blurcontrolbuttonnormal {
  border-radius: 0px;
  border-right: 0px;
  border-left: 0px;
}
.blurcontrolbuttonhigh {
  border-radius: 0px 6px 6px 0px;
}

/*Buy Premium Page*/
.buy-background {
  background: #fff;  
}
.premium-logo {
  padding-top: 55px;
}
.buy-install-blur {
  display: inline-block;
  margin-left: auto;
}
.buy-affiliate-banner {
  margin-top: -15px;
  background-color: #F5F6F8;
  padding-top: 5px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.buy-affiliate {
	display: flex !important;
  align-items: center;
  font-size: 0.8em;
  flex-wrap: nowrap;
  justify-content: flex-start;
  flex-direction: row;
}
.navmenu-affiliate .ui.container {
	display: flex;
  align-items: center;
  font-size: 0.8em;
  flex-wrap: nowrap;
}
.navmenu-affiliate img, .buy-affiliate img {
  margin-right: 20px;
  max-height: 50px;
  max-width: 250px;
}
@media screen and (max-width: 768px) {
  .buy-affiliate {
    flex-wrap: wrap;
  }
  .navmenu-affiliate img {
    margin-left: 20px;
  }
  .navmenu-trial-message {
    margin: 20px 20px 0px 20px !important;
  }
  .navmenu-affiliate .ui.container {
    flex-wrap: wrap;
  }
}
.navmenu-affiliate .info_icon, .buy-affiliate .info_icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px 8px;
  background: #777777;
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
.navmenu-affiliate a, .buy-affiliate a {
  text-decoration: underline;
}
.navmenu-affiliate-section {
  display: flex;
  align-items: center;
  margin-right: 40px;
  min-width: 250px;
  flex-wrap: wrap;
}
.navmenu-affiliate-logo {
  max-height: 50px !important;
  max-width: 250px !important;
}
.navmenu-affiliate-info {
  margin-top: 5px;
  margin-bottom: 5px;
}
.navmenu-trial-message {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.navmenu-welcome-margin {
  margin-right: 100px;
}
.navmenu.ui.inverted.text.menu .dropdown .item {
  background-color: #f5f6f8 !important;
}
.navmenu.ui.inverted.text.menu .dropdown .item:hover {
  background-color: lightgray !important;
}
.button:active, .button:hover {
  transform: scale(1.02) perspective(1px);
  transition: 0.2s;
}
.buy-premium-title {
  color: #47BA77 !important;
}
.buy-comparison-div {
  display: flex;
}
.buy-comparison-card {
  color: #506D84;
  border: 1px solid #328ec9;
  border-radius: .25rem;
  text-align: center;
  margin-right: 5px;
  min-width: 180px;
  margin-top: 15px;
  box-shadow: 0 0 2px #9aaba1;
  display: inline-table;
}
.recommended-card {
  min-width: 200px;
  border: 2px solid #47ba77;
  box-shadow: 0 0 5px #9aaba1;
  margin-top: 0px;
}
@media screen and (max-width: 1050px) {
  .buy-comparison-card {
    width: 100%;
    min-width:  auto;
  }
}
@media screen and (max-width: 480px) {
  .buy-comparison-div {
    display: block;
  }
  .recommended-card {
    margin-top: 15px;
  }
}
.buy-card-info-icon {
  color: #506D84;
}
.buy-card-fullprice {
  text-decoration: line-through;
  padding-top: 15px;
  font-size: 14px;
}
.buy-card-fullprice-empty {
  padding-top: 15px;
  font-size: 14px;
}
.buy-card-payprice {
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
}
.buy-card-taxes {
  font-size: 0.75em;
  color: #506D84;
}
.buy-card-taxes .taxplus {
  margin-right: 3px;
}

.buy-card-payprice strong {
  font-size: 1.2em;
}
.buy-card-savepercent {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 600;
}
.text-green {
  color: #47ba77;
}
.text-blue {
  color: #328ec9;
}
.buy-comparison-highlight {
  margin-top: 20px;
}
.buy-main {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  background-color: #d9e6f0
}
.buy-main-first {
  color: rgba(0,0,0,.6);
  width: 60%;
  min-width: 550px;
  min-height: 100%;
  padding-left: 20px;
  padding-top: 5em;
  padding-right: 20px;
  background-color: #FFF;
}
.buy-first-block {
  width: 550px;
  margin: auto;
  margin-right: 2%;
}
.buy-logo {
  display: flex;
  justify-content: space-between;
  margin-right: 10%;  
}
.buy-logo-img {
  width: 150px;
}
.buy-header {
  font-size: 1.3em;
  font-weight: bold;
  padding-top: 2em;
}
.buy-subsc-section, .buy-subsc-section-info {
  padding-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;  
}
.buy-subsc-header {
  color: #328FC9;
  font-weight: bold;
  font-size: 0.9em;
}
.buy-subsc-detail {
  font-size: 0.8em;
}
.buy-subsc-breakup, .buy-subsc-info {
  padding-left: 10px;
}
.buy-subsc-breakup .ui.dropdown {
  background-color: #EBF6FC;
  font-weight: bold;
  border-bottom: 1.5px solid rgba(0,0,0,.4);
  width: 95px;
  min-width: 85px !important;
  border-radius: 0px !important;
  border-left: none;
  border-top: none;
  border-right: none;
}
.buy-subsc-premium-breakup .ui.dropdown {
  background-color: #EBF6FC;
  font-weight: bold;
  border-bottom: 1.5px solid rgba(0,0,0,.4);
  width: 294px;
  min-width: 200px !important;
  border-radius: 0px !important;
  border-left: none;
  border-top: none;
  border-right: none;
  padding-top: 7px;
  padding-bottom: 7px;
}
.buy-premium-options td:nth-child(1) {
  width: 200px;
}
.buy-premium-options td:nth-child(2) {
  width: 94px;
  color: #506D84;
} 
.buy-subsc-breakup .ui.dropdown .text, .buy-subsc-premium-breakup .ui.dropdown .text {
  font-size: 0.8em;
}
.buy-subsc-total {
  padding-left: 25px;
  font-size: 1.1em;
  font-weight: 600;
  color: #328FC9;
}
.buy-subsc-icon {
  width: 40px;
  height: 40px;
  background-color: #328FC9;
}
.buy-subsc-img {
  margin-top: 4px;
  margin-left: 5px;
  width: 30px;
}
.buy-subsc-emails, .buy-subsc-coupon-content {
  padding-top: 1em;
}
.buy-subsc-coupon-applied {
  font-size: 0.9em;
  font-weight: 400;
  color: #47ba77;
}
.buy-subsc-coupon-applied .buy-subsc-coupon-remove-link {
  float: right;
}
.buy-subsc-emails .ui.input, 
.buy-subsc-coupon .ui.input,
.buy-card-name-on-card .ui.input {
  margin-top: 10px;
  margin-bottom: 10px;
}
.buy-subsc-emails .ui.input .ui.label,
.buy-subsc-coupon .ui.input .ui.label,
.buy-card-name-on-card .ui.input .ui.label {
  background-color: white !important;
  font-size: 14px;
  font-weight: bold;
  padding-top: 13px;
  border-left: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.buy-subsc-emails input,
.buy-subsc-coupon input,
.buy-card-name-on-card input {
  background-color: white !important;
    font-size: 14px;
    font-weight: 400;
    flex: auto !important;
    height: 40px;
}
.buy-subsc-coupon .ui.button {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 400;
  margin-left: 10px;
}
.buy-subsc-coupon {
  padding-bottom: 0px;
}
.buy-subsc-card {
  padding-top: 0.3em;
}
.buy-subsc-card .ui.input {
  margin-bottom: 1.5em;
}
.buy-card-name {
  height: 40px;
}
.buy-subsc-secure {
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  color: #328FC9;
}
.buy-subsc-secure i {
  font-size: 1.5em;
}
.buy-subsc-secure img {
  height: 30px;
  width: auto;
}
.buy-subsc-secure .buy-secure-text {
  text-transform: uppercase;
  font-size: 0.6em;
  line-height: 1.3;
  margin-right: 10px;
}
.buy-main-second {
  width: 40%;
  min-width: 400px;
  background-color: #D9E6F0;
  min-height: 100%;
}
.buy-devider {
  margin: 0.5rem 0rem !important;
}
.buy-summary {
  top: 9em;
  margin-top: 6em;
  margin-left: 4em;
  margin-bottom: 20px;
  width: 300px;
  min-height: 400px;
  color: rgba(0,0,0,.6) !important;
  position: sticky;
  box-shadow: 0px 3px 20px #7692a2;
  background-color: #FFF;
  padding: 20px;
}
@media screen AND (max-width: 1000px) {
  .buy-summary {
    margin-top: 3em;
  }
}
.buy-summary:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.5em;
  background-size: 1em 100% !important;
  background-position: center;
  top: 100%;
  background:
    linear-gradient(135deg, #FFF 33.333%, transparent 0), 
    linear-gradient(-135deg, #FFF 33.333%, transparent 0);
}
.buy-summary .buy-logo {
  text-align: center;
  padding: 20px;
}
.buy-summary .buy-logo-img {
  width: 110px;
}
.buy-quotation {
  margin-top: 1em;
  width: 90%;
  font-size: 0.8em;
}
.buy-quotation-right {
  text-align: right;
}
.buy-summary-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;  
  justify-content: space-between;
}
.buy-summary-header {
  font-size: 0.9em;
  font-weight: bold;
}
.buy-summary-header a {
  padding-left: 5px;
  font-weight: 200;
}
.buy-summary-detail {
  font-size: 0.7em;
}
.buy-summary-detail .dropdown .item .text {
  font-size: 0.7em;
}
.buy-summary-detail .ui.selection.dropdown {
  min-width: 7em !important;
  height: 35px;
}
.buy-summary-total {
  font-size: 0.9em;
}
.buy-summary-final {
  font-size: 1em !important;
  color: #328FC9 !important;
  font-weight: bold !important;
}

.buy-summary-terms {
  font-weight: 500;
  font-size:0.7em;
  line-height: 1.3;
  padding-top: 2em
}
.buy-summary-terms a:hover {
  text-decoration: underline;
}
.buy-summary-terms a {
  cursor: pointer;
}
.buy-summary-checkout {
  padding-top: 1em;
  font-weight: 500;
}
.buy-summary-checkout .button {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9em;
}
.buy-summary-highlight {
  display: flex;
  padding-top: 1em;
  font-size: 0.8em;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.buy-summary-highlight img {
  height: 60px;
  width: auto;
  margin-right: 10px;
}
.buy-summary-highlight-title {
  font-size: 1.1em;
}
.buy-summary-highlight-descr {
  line-height: 1.3;
  font-size: 0.9em;
}

.buy-footer {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  justify-content: space-around;
}
.buy-coupon-invalid {
  text-align: left;
  font-size: 0.9em;
  color: #ff4d4d;
  padding-top: 15px;
}
.buy-error {
  margin-top: 1em;
  margin-bottom: -1.5em;
  color: #ff4d4d;  
}
.buy-card-blur {
  filter: blur(3px);
  pointer-events: none;
}
.buy-email-exist {
  margin-top: 4em;
  margin-bottom: -1.5em;
  width: 90%;
}
.buy-email-exist {
  font-size: 0.8em;
}
.buy-email-exist a:hover{
  text-decoration: underline;
}
.buy-benefits-list .item {
  padding-bottom: 7px !important;
}
.buy-plan-button {
  padding-top: 25px;
  padding-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.buy-plan-button .ui.button {
  margin: 0;
}
.buy-options {
  cursor: pointer;
}
.buy-options:hover {
  background: #f6fbff;
}
.buy-best-option {
  position: relative !important;
  border: 2px solid #47BA77 !important;
}
.buy-best-option-label {
    font-size: 0.9em;
    color: white;
    background: #47BA77;
    padding: 4px 14px;
}
.buy-quotation-box {
  position: relative;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
  background: #506D84;
}
.buy-quote-icon {
  background: #eff3f7;
  height: 55px;
  padding-top: 10px;
  border-radius: 40px;
  width: 55px;
  padding-left: 8px;
  margin-left: auto;
  margin-right: auto;
}
.buy-quotaion-author {
  font-size: 0.8em;
  text-align: right;
  line-height: 130%;
  margin-top: 10px;
}
.buy-steps {
  font-size: 0.8em;
  color: #506D84;
}

.no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;  
}

.cookies-notice {
	width: 100%;
	background: #032941;
	position:	fixed;
	bottom: 0px;
	z-index: 100;
  color: white;
}
.cookies-notice	.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .cookies-notice	.container {
    display: flex;
  }
}
.cookies-notice	.left {
  font-size: 0.9rem;
  text-align: left;
}
.cookies-notice	.right {
  width: 500px;
  padding-top: 10px;
  padding-left: 20px;
  text-align: center;
}
.cookies-notice	.right a {
  font-size: 0.9rem;
  margin-left: 10px;
}
.cookies-notice	a {
  text-decoration: underline !important;
  color: white;
  white-space: nowrap;
}
.cookies-notice	a:hover {
  text-decoration: none !important;
  color: white;
}
.cookies-notice	button {
  min-width: 130px;
}

.socialShareContainer {
  line-height: 1.5;
  display: flex;
}
.socialShareContainer i {
  font-size: 30px;
}
.socialShareContainer .socialShare-icons {
  white-space: nowrap;
}
.socialShareContainer .socialShare-text {
  align-self: center;
  margin-left: 10px;
}

.nowrap {
  white-space: nowrap;
}

/* MyCommerce fix styling*/
.navbar-header {
  height: auto !important;
}

/* InstallAd.js */
.verbose-ad-card {
  color: #506D84;
  border: 2px solid #328ec9;
  border-radius: .25rem;
  text-align: center;
  width: 100%;
  box-shadow: 0 0 2px #9aaba1;
  display:inline-block;
  padding: 25px;
}
.verbose-ad-card div {
  padding: 15px 60px;
  font-size: 1.3em;
  line-height: 140%;
}

/* App.js */
.accordion-title, .accordion-title-active {
  font-size: 1.2em;
}
.accordion-title {
  border-radius: .25rem;
  border: 2px solid #e7e7e7;
  padding: 20px;
}
.accordion-title-active {
  background-color: #F5F6F8;
  border-radius: .25rem;
  border: 2px solid #e7e7e7;
  padding: 20px;
}
.accordion-title p {
  font-size: 1.2em;
  line-height: 140%;
  float: left;
}
.accordion-title-icon {
  float: right;
}
.apps-deceptor-text {
  color: #f6921e;
  font-size: 1em;
  font-weight: bold;
  border: 2px solid #f6921e;
  padding: 10px 20px;
}
.apps-deceptor-text i {
  font-size: 1.5em;
}
.apps-certified-text {
  position: absolute;
  top: -20px;
  color: #f6921e;
  font-size: 1em;
  font-weight: bold;
}

/* AppSearchBar.js */
.searchbar p {
  font-size: 1.2em;
  float: left;
}
.searchbar-deceptor {
  color: rgb(200, 0, 0);
  float: right;
}
.searchbar-certified {
  color: rgb(0, 200, 0);
  float: right;
}
.text-danger {
color: #F57C23;
}
.text-danger {
	color: #F57C23;
}
.font-large {
  font-size: 1.2em;
}
.font-xlarge {
  font-size: 1.6em;
}
.text-capitalize {
  text-transform: capitalize;
}
.expireDaysLeft {
  color: #F57C23;
}
.notify-btn-preselected {
  background-color: #3593D2;
  color: white;
}
.trial-notify-text {
  font-size: 1.3em;
}
.trial-notify-switch {
  margin-top: 5px;
}
.trial-notify-cta {
  margin-left: 25px;
}
.trial-notify-uninstall-link {
  margin-top: 5px;
}
.display-no {
  display: none;
}
.display-flex {
  display: flex;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 16px;
  cursor: pointer;
  top: 2px;
  margin-right: 5px;
  margin-left: 5px;
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F57C23;
}
.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  bottom: -2px;
  background-color: white;
  -webkit-box-shadow: 0px 1px 3px 1px  #d0c9c4;
  box-shadow: 0px 1px 3px 1px #d0c9c4;
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.slider-untoggled:before {
    left: 0px;
    transition: left 0.1s;
}
.switch .slider-toggled {
    background-color: #47ba77;
}
.slider-toggled:before {
    left: 20px;
    transition: left 0.1s;
}
.search-slider {
    background-color: #C2CED8;
    text-transform: capitalize;
}
.search-searchbar-custom-header-wrap {
  background-color: #506D84;
  width: 100%;
  margin: 0px 0px 15px 0px;
  padding: 5px 0px 10px 0px;
  position: fixed;
  top: 0px;
  z-index: 100;
}
.search-searchbar-custom-header {
  display: flex;
  justify-content: left;
  align-items: center;
}
.search-searchbox-searchbar {
    width: 500px;
    margin-right: 30px;
}
.search-searchbox-baritems {
    display: flex;
    align-items: center;
    margin-left: 200px;
}
@media screen and (min-width: 400px) and (max-width: 650px) {
  .search-searchbox-baritems {
    margin-left: unset;
    justify-content: center;
  }
}
@media screen and (max-width: 399px) {
  .search-searchbox-baritems {
    margin-left: unset;
    justify-content: center;
    flex-direction: column;
  }
}
.search-safe-switch {
  color: white;
}
.search-safe-switch > input {
  vertical-align: sub;
}
.search-safe-switch > label {
    font-size: x-small;
    margin: 1px 0px 0px 3px;
}
.search-safe-switch.home {
  font-size: 0.7em;
  text-align: center;
}
.search-safe-switch.home > input {
    margin-left: auto;
    vertical-align: sub;
}
.search-safe-switch.home > label {
    margin-right: 14px;
}
.search-english-switch {
    margin: 0px 30px 0px auto;
    align-self: center;
    text-transform: capitalize;
}
.search-searchbox-text {
  margin: 0px 30px 0px 27px;
}
.search-searchbar-custom-header .navmenu-logo-img {
  width: 100px;
  height: auto;
  margin-top: 6px;
  margin-bottom: 2px;
}
@media screen AND (max-width: 767px) {
  .mobile-hide {
    display: none;
  }
  .page-content, .sidebar-panel  {
    margin-top: 60px;
    padding: 5px;
  }
}
@media screen AND (min-width: 768px) {
  .desktop-hide {
    display: none;
  }
}
.search-searchbox-text {
  color: white;
  font-size: 0.8em;
}
/* New tab home */
.home-searchbox-text {
  padding-bottom: 10px;
  margin-top: 190px;
  /*color: #FFF;*/
  text-align: center;
}
.home-searchbox-searchbar {
  margin-right: auto;
  margin-left: auto;
  width: 70%;
}
.row-cards {
  display: flex;
  flex-flow: row;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.home-page-content {
  flex: 1;
  display: flex;
  justify-content: center;
}
.home-page-content .home-side-card {
  border: none;
  width: 270px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1340px) and (max-width: 1500px) {
  .home-page-content {
    justify-content: left;
    margin-left: 15%;
  }
}
@media (min-width: 1230px) and (max-width: 1339px) {
  .home-page-content {
    justify-content: left;
    margin-left: 7%;
  }
}
@media (min-width: 1024px) {
  .home-side-section {
    margin-right: 20px;
  }
  .home-side-section .home-side-card {
    margin-top: 5px;
  }
  .home-cards-wrap > .home-card.apps-card, .home-cards-wrap >.home-card.blog-card, .home-cards-wrap >.home-card.domain-card {
    margin-left: 10px;
  }
}
@media (min-width: 1230px) {
  .home-cards-wrap > .home-card.apps-card, .home-cards-wrap >.home-card.blog-card, .home-cards-wrap >.home-card.domain-card {
    width: 270px !important;
    margin-left: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1229px) {
  .home-page-wrapper {
    height: 100%;
  }
  .home-page-wrapper > .home-side-panel {
    position: relative;
    margin-left: 20px;
  }
  .home-content-wrap {
    align-self: center;
  }
  .home-cards-wrap > .home-card.apps-card, .home-cards-wrap >.home-card.blog-card, .home-cards-wrap >.home-card.domain-card {
    width: 35% !important;
    margin-left: 10px;
  }
  .home-searchbox-text {
    margin-top: 20px;
  }
}
@media (max-width: 1023px) {
  .home-page-wrapper {
    flex-direction: column !important;
  }
  .home-side-panel {
    position: unset !important;
  }
  .home-searchbox-text {
    margin-top: 20px;
  }
  .home-content-wrap .navmenu-logo-img {
    display: block;
    width: 128px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
  .home-cards-wrap > .home-card.apps-card, .home-cards-wrap >.home-card.blog-card, .home-cards-wrap >.home-card.domain-card {
    width: 30% !important;
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
  .home-cards-wrap > .home-card.apps-card, .home-cards-wrap > .home-card.blog-card, .home-cards-wrap > .home-card.domain-card {
    width: 300px !important;
  }
  .home-content-wrap {
    min-width: 100% !important;
  }
  .home-cards-wrap {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .home-page-content {
    display: inline-block;
  }
}
.home-page-wrapper {
  display: flex;
}
.home-side-panel {
  margin-top: 20px;
  position: fixed;
  right: 0px;
}
.home-side-panel .setBlurImage {
  margin-right: 4px;
}
.home-side-panel .setBlurText {
  font-size: 8px;
}
.home-side-section {
  text-align: center;
}
.home-side-section .navmenu-logo-img {
  margin: auto;
}
.home-side-section .affiliate-info {
  margin-top: 20px;
  font-size: 0.8em;
  color: #FFF;
}
.home-side-section .affiliate-info a {
  color: #FFF;
}

.home-side-section .affiliate-info img {
  max-height: 50px;
  max-width: 240px;
  background-color: #EEE;
  border-radius: 2px;
}
.home-side-section .home-side-btn {
  margin-top: 5px;
  margin-right: 0px;
}
.home-side-status {
  padding: 10px 10px;
  line-height: 1.4;
}
.home-side-status a {
  text-decoration: underline;
  font-size: 0.8em;
}
.home-side-status .text-danger, .home-side-status .expireDaysLeft {
  color: #F57C23;
}
a:hover, a:active {
  transform: scale(1.02) perspective(1px);
  transition: 0.2s;
}
.home-content-wrap {
  min-width: 650px;
}
.home-cards-wrap {
  display: flex;
  justify-content: center;
  /*margin-top: 60px;*/
}
.home-content-wrap .navmenu-logo-img {
  display: none;
}
.home-card > div {
  padding: 15px;
  background-color: rgba(255,255,255,0.7);
  border-radius: 10px;
  font-size: 0.9em;
  margin-top: 5px;
}
.home-card:hover .ui.input.fluid > .home-card-input {
  opacity: 1.0;
}
.ui.input.fluid > .home-card-input {
  border-radius: 0.75rem;
  margin: 5px 0px 10px 0px;
  opacity: 0.6;
}
.home-side-card {
  padding: 10px 0 0 0;
  background-color: rgba(255,255,255,0.7);
  border-radius: 10px;
  font-size: 0.9em;
  color: #506D84;
  border: solid 1px #c8d2de;
}
.home-side-card-footer a {
  cursor: pointer;
  color: white;
  text-decoration: none;
  white-space: nowrap;
}
.home-side-card-footer a:hover {
  text-decoration: underline;
}
.home-side-card-footer {
  font-size: 0.8em;
  background-color: #506D84;
  color: #FFF;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  word-break: break-word;
}
.card-header {
  margin-left: 5px;
  margin-bottom: 10px;
}
.home-card .domain-card-item {
  display: flex;
  cursor: pointer;
}
.home-card .domain-card-name {
  margin-left: 5px;
}
.home-card .domain-card-icon {
  padding: 2px;
  height: 24px;
  width: auto;
}
.card-apps-list {
  margin: 10px 5px;
  line-height: 1.2;
}
.card-apps-link {
  display: flex;
  margin-bottom: 8px;
}
.card-apps-link i, .card-apps-link a {
  text-align: left;
}

.card-search-paginator {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 15px;
    margin-bottom: -8px;
}

.card-search-results-text {
    font-size: x-small;
    color: #506D84;
    margin-left: 5px;
}

.cs-paginator-item {
    min-width: 25px;
    align-self: flex-end;
    white-space: nowrap;
}

.cs-paginator-item.shift-margin {
    margin-bottom: 1px;
}

.cs-paginator-item > button {
    background: transparent;
    border: none;
}

.cs-paginator-item > button:active {
    transform: translateY(2px);
}

.cs-arrow-left:before {
    content: '\25C0';
}

.cs-arrow-right:before {
    content: '\25B6';
}

.cs-skip-arrow-left:before {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 18 18' width='16' height='16'><path fill='currentColor' d='M6,18V6H8V18H6M9.5,12L18,6V18L9.5,12Z'/></svg>");
}

.cs-skip-arrow-right:before {
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 18 18' width='16' height='16'><path fill='currentColor' d='M16,18H18V6H16M6,18L14.5,12L6,6V18Z'/></svg>");
}

.search-content-wrap {
  display: flex;
  min-width: 1024px;
}
.search-results {
  margin-left: 180px;
  width: 652px;
  margin-top: -15px;
}
.affiliate-info a {
  text-decoration: underline;
}
.gsc-input .gsc-input-box {
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.gsc-search-button svg {
  max-width: none !important;
}
.gsc-search-box td.gsc-input {
  padding-right: 0px !important;
}
.gsc-search-button button {
  margin-left: -1px;
  height: 44px;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}
/*TODO, we should never write a style without our own wrapper*/
.ui.cards > .card, .ui.card {
  width: 200px !important;
}
