.blog-sidebar-ad {
  padding:2px;
  padding-top:0px;
  border: 1px solid #c8d2de;
  background-color: rgba(255,255,255,0.7);
  border-radius: 10px;
  font-size: 0.9em;
  color: #506D84;
  margin: 5px auto 5px auto;
  text-align: center;
  min-height: 40px;
}
@media (min-width: 678px) and (max-width: 1023px) {
  .blog-sidebar-ad {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .blog-sidebar-ad {
    margin-bottom: 20px;
    overflow: hidden;
  }
}
.blog-sidebar-ad-text {
  clear: both;
  color: rgba(#506D84, 0.5);
  float: left;
  letter-spacing: 2px;
  margin-bottom: 5px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;    
  font-size: 0.6em;
}
.blog-sidebar-ad-slot {
  margin-left: auto;
  margin-right: auto;
}

.sidebar-wrap {
  background-color: #F5F6F8;
  padding-bottom: 20px;
}
.sidebar-panel .home-side-section {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1024px) {
  .sidebar-wrap {
    width: 400px;
  }
  .sidebar-panel {
    max-width: 350px;
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 90px;
  }
  .sidebar-panel .home-card {
    width: auto !important;
  }
  .sidebar-wrap .home-side-card {
    width: 340px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .sidebar-wrap {
    width: 100%;
  }
  .sidebar-panel {
    max-width: 95%;
    margin-top: 40px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sidebar-panel .home-card {
    width: 270px;
    margin-right: auto;
    margin-left: auto;
    margin: 5px;
  }
  .sidebar-cards-wrap {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .sidebar-panel .home-card {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
.sidebar-panel .home-card > div {
    border: solid 1px #c8d2de;
}
.sidebar-panel .affiliate-info, .sidebar-panel .affiliate-info a {
  color: #212121 !important;
}
.home-side-extension-info-section {
  box-shadow: 0px 7px 5px 0px rgba(164,156,156,0.75);
  background-color: #F5F6F8;
  margin-bottom: 10px;
}
.home-side-blur-control-text {
  height: auto;
  margin-bottom: 5px;
  text-align: left;
}
.setBlurText {
  font-size: 11px;
  margin: auto 1px;
  }
.setBlurImage .st0{fill:#FFFFFF;}
.setBlurImage .st1{fill:#3EA1D2;}
.setBlurImage .st2{fill:#4EC1EF;}
.setBlurImage .st3{fill:#F7DA2C;stroke:#58595B;stroke-miterlimit:10;}
.setBlurImage .st4{fill:#F9BE2B;stroke:#58595B;stroke-miterlimit:10;}
.setBlurImage .st5{fill:#58595B;}
.setBlurImage .st6{fill:#F7DA2C;}
.setBlurImage .st7{fill:#F9BE2B;}
  
.setBlurBox {
  display: flex;
}
.home-side-blurring-results {
  justify-content: space-between;
  align-items: center;
}
.setBlurImage {
  border: 1px solid #ddd;
  margin-right: 6px;
  cursor: pointer;
  transition: transform .2s; /* Animation */
  background-color: #FFF;
}
.setBlurImage:hover {
  transform: scale(1.2);
}