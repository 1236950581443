.domains-blurring-statistics-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #506d84;
    color: #FFF;
    padding: 6px;
    text-align: center;
    border-radius: 4px;
    height: 100%;
    min-height: 175px;
    min-width: 175px;
}
.domains-blurring-number {
    font-size: 2.5em;
}
.domains-blurring-p {
    font-size: 1em;
}
.rankvalue {
  text-align: right;
}
.domains-ranks-panel {
  display: inline-block;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px;
  text-align: left;
  border-radius: 4px;
  min-width: 175px;
  font-size: 1em;
  margin-left: 15px;
}
.domains-ranks-panel-title {
  margin-bottom: 10px;
}
.domains-ranks-ul {
  list-style: none;
}
.domains-ranks-mini {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 15px;
  background-color: #e1e8ef;
  text-align: left;
  border-radius: 4px;
  height: 100%;
  min-height: 40px;
  min-width: 175px;
}
.bordered {
  border-radius: .25rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  padding: 10px;
}
.domains-around-world {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.domains-around-world-map {
  width: 60%;
}
.domains-around-world-top {
  width: 40%;
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .domains-around-world-map {
    width: 100%;
  }
  .domains-around-world-top {
    width: 100%;
  }
  .domains-ranks-panel {
    width: 100%;
    margin-left: 0px;
  }
  .domains-table-top {
    width: 100%;
  }
  .domains-ranks-panel-title {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .domains-around-world-map {
    width: 100%;
  }
  .domains-around-world-top {
    width: 100%;
  }
  .domains-ranks-panel {
    width: 100%;
    margin-left: 0px;
  }
  .domains-table-top {
    width: 100%;
  }
  .domains-ranks-panel-title {
    margin-top: 20px;
  }
}
table.ranks {
  word-break: break-word;
  text-align: left;
}
.domains-top-link {
  text-decoration: underline;
  font-size: 0.8rem;
  clear: both;
  display: inline-block;
}
.domains-top-rank-number {
  font-weight: 300;
}
.domains-top-link:hover {
  text-decoration: underline;
}
.domains-table-top {
  font-size: 0.75rem;
  border-collapse: collapse;
}
.domains-table-top tr:nth-child(even) {
  background-color: #fdfdfd;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.domains-table-top tbody tr:first-child {
  border-top: 1px solid #dededf;
}
.domains-table-top tbody tr:last-child {
  border-bottom: 1px solid #dededf;
}
.domains-table-top tr td:first-child {
  border-left: 1px solid #dededf;
}
.domains-table-top tr td:last-child {
  border-right: 1px solid #dededf;
}
.domains-table-top td.link {
  cursor: pointer;
  color: #4183C4;
}
.domains-table-top td.link:hover {
  text-decoration: underline;
}
.domains-table-top th {
  font-size: 0.7rem;
  font-weight: 400;
}
.domains-table-top th:nth-child(2), .domains-table-top th:nth-child(3),
.domains-table-top td:nth-child(2), .domains-table-top td:nth-child(3)
 {
  text-align: right;
}
.domains-table-top th, .domains-table-top td {
  padding: 5px;
}
@media screen and (max-width: 767px) {
  .search-english-switch {
    display: none;
  }
}
.empty-wordcloud {
  text-align: center;
  line-height: 300px;
  height: 300px;
  width: 300px;
  font-style: italic;
  font-weight: bold;
}
.domains-worldmap {
  position: relative;
}
.domains-worldmap button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border: 1px solid #b8c4cd;
  border-radius: 4px;
  color: #4f6d84;
  background-color: #fafcfd;
}
.domains-worldmap button:hover {
  cursor: pointer;
  transform: scale(1.1) perspective(1px);
  transition: 0.2s;
}
.domains-worldmap button i {
  padding-top: 2px;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.domains-actions-dropdown, .domains-actions-dropdown .item {
  font-size: 0.75rem !important;
}
.domains-stats-switch {
  margin-top: 5px;
}
.domains-stats-switch label {
    font-size: 0.75rem !important;
    margin: 1px 0px 0px 3px;
}
.domains-stats-switch label:before {
  width: 15px !important;
  height: 15px !important;
}
.domains-tile-description {
  font-weight: 300;
}