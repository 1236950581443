/*******************************
           Overrides
*******************************/

h1.ui.header,
.ui.huge.header {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 1.3;
}

h4.ui.header {
  font-size: 1.12em;
  line-height: 1.2;
}
